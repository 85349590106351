import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import '../../assets/styles/base/standard-page.scss';
import '../../assets/styles/pages/accident-management.scss';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import TechnicalHeader from '../../images/crash-management/Technical-Header.jpg'
import AccidentSubNav from "../../components/accident-sub-nav";

const AccidentManagementFaq = () => {
  const data = useStaticQuery(graphql`
    query {
      general {
        country_code
      }
    }
  `);
  return (
    <Layout wrapperClass="standard-page" showSiteIndex={true} hideHeaderFooter={true}>
      <SEO title="DingGo | Accident Management FAQ" />
      <AccidentSubNav />
      <div className="hero-image">
        <div className="container">
          <img src={TechnicalHeader} />
        </div>
      </div>
      <div className="main-wrapper">
        <section className="py-7">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h1>Crash Management automotive repair service – FAQs</h1>

                <div className="entry-content" itemProp="mainContentOfPage">
                  <h3>1. Doesn’t my Insurance Company direct me to a repairer?</h3>
                  <p><strong>No.</strong> Whilst some insurance companies do have repairers they can recommend, the
                    insured’s right to choose will always be upheld. This includes your right to choose the services of
                    an independent car accident management firm.</p>
                  <p>We link seamlessly with insurance companies and insurance brokers, without duplication or conflict.
                    No insurer or individual repair shop can compete with Crash Management’s level or scope of service,
                    or the quality and convenience of our delivery.</p>
                  <h3>2. Do I lose my insurance company guarantee if I don’t use one of their recommended
                    repairers?</h3>
                  <p>First and foremost you are very well protected by the Consumer Guarantees Act.&nbsp; The
                    panelbeater must guarantee all workmanship, and Crash Management will enforce and manage this
                    process on your behalf.&nbsp; The repairer is also an insurance company’s first line of
                    guarantee.&nbsp;At the completion of repairs our customers receive a comprehensive Warranty
                    Assurance document.&nbsp; In the event of any fault or oversight, we encourage customers to contact
                    us in the first instance so that we can manage any remedial work, minimise inconvenience and
                    time-off-the-road.</p>
                  <h3>3. Doesn’t the tow truck driver/company direct me to a repairer?</h3>
                  <p><strong>No.</strong> The tow truck driver and/or company has no automatic right to arrange
                    collision repairs on your vehicle, and has no right to pressure you into handing over control of
                    your vehicle repair to them. Their function is simply to arrange safe removal and temporary storage
                    of an immobilised vehicle.</p>
                  <h3>4. How does my vehicle get from the tow-truck company to a Crash Management service provider?</h3>
                  <p>Our car accident management service is very comprehensive, we will manage all arrangements on your
                    behalf and settle any towage/storage account for you.</p>
                  <h3>5. Will my vehicle ever be the same again?</h3>
                  <p><strong>Yes. </strong>Our repairers have been carefully selected against rigorous performance
                    standards in technical capability, facility specification, quality control and customer
                    satisfaction. Very simply, they are the best in the business.&nbsp; Using international
                    best-practice processes and the most up-to-date equipment, your collision repairs will be completed
                    precisely to manufacturers’ specifications, from structural (chassis and wheel alignment), to
                    componentry, to paint finish.&nbsp; Safety and quality is the primary focus and will not be
                    compromised.</p>
                  <p><a title="a graphic illustration of the consequences of substandard structural car repairs"
                        href="http://www.youtube.com/watch?v=M1JWAc2OIcY" target="_blank" rel="noopener noreferrer">Click
                    this link to view</a> a graphic illustration of the consequences of substandard structural repairs.
                    This video is well worth the 5 minutes you’ll invest in seeing what even your insurance company
                    won’t see after these repairs are completed.&nbsp; Without Crash Management on your side, this could
                    happen to you.</p>
                  <h3>6. Doesn’t an auto accident lower the value of my vehicle?</h3>
                  <p>Cheap and/or poor quality collision repairs will certainly lower the value of your vehicle. Poor
                    quality auto body repairs can also result in unidentified hidden damage, consequential and/or
                    premature failures of associated components including excessive tyre wear. Needless to say, poor
                    quality collision repairs pose serious safety concerns which may not be identified until your next
                    WOF test – by then it may be too late.</p>
                  <p>Poor paint colour matching is the most visible, but the least of your worries with poor quality
                    collision repair.</p>
                  <h3>7. How does Crash Management Services identify and select high quality repairers?</h3>
                  <p>Our significant expertise in the collision repair industry has resulted in a highly effective
                    nationwide network. This ensures technical standards are maintained in accordance with manufacturers
                    specifications and supported by the high customer service standards detailed in our service level
                    agreements with repairers. We also work with insurance company ‘preferred repairers’ wherever
                    possible.</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default AccidentManagementFaq;